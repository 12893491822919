import {Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AComboBoxGenericComponent} from '../a-combo-box-generic/a-combo-box-generic.component';

const CB_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AComboBoxComponent),
  multi: true
};

/**
 * @deprecated : Use AComboBoxGeneric instead
 */
@Component({
  selector: 'a-combo-box',
  templateUrl: '../a-combo-box-generic/a-combo-box-generic.component.html',
  styleUrls: ['../a-combo-box-generic/a-combo-box-generic.component.scss'],
  providers: [CB_VALUE_ACCESSOR]
})
export class AComboBoxComponent extends AComboBoxGenericComponent<string> {}
