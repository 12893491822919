import {Document} from '../document/Document';
import {Folder} from './Folder';
import {Type} from 'class-transformer';

export class FolderContent {
  @Type(() => Document)
  public documents: Document[];
  public folders: Folder[];
  public moreDocuments = false;
  public moreFolders = false;
}
