import {Injectable} from '@angular/core';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ControlDataSource} from '../../domain/person/person/ControlDataSource';
import {ControlDataSourceDescription} from '../../domain/person/person/ControlDataSourceDescription';

@Injectable()
export class ControlDataSourceService {
  private readonly baseUrl = '/api/control-data-sources';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public findAllDefault(): Observable<ControlDataSource[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlDataSource)
            .get<ControlDataSource[]>(url + '/default')
        )
      );
  }

  public findAllByCurrentCompany(): Observable<ControlDataSource[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlDataSource)
            .get<ControlDataSource[]>(url + '/company/current')
        )
      );
  }

  public getControlDataSourceByCustomControlTypeId(controlType: number): Observable<ControlDataSource> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlDataSource)
            .get<ControlDataSource>(`${url}/person-custom-control/${controlType}`)
        )
      );
  }

  public updateControlDataSourceDescription(
    controlDataSourceId: string,
    controlDataSourceDescription: string,
  ): Observable<ControlDataSource> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlDataSource)
            .put<ControlDataSource>(
              `${url}/${controlDataSourceId}/description`,
              new ControlDataSourceDescription(controlDataSourceDescription)
            )
        )
      );
  }
}
