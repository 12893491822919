import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {ControlIdAndName} from '../../domain/person/person/control/ControlIdAndName';
import {PersonCustomControl} from '../../domain/person/person-custom/PersonCustomControl';
import {PersonCustomControlCreation} from '../../domain/person/person-custom/PersonCustomControlCreation';

@Injectable()
export class PersonCustomControlService {
  private readonly baseUrl = '/api/person-custom-controls';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getAllControlsList(): Observable<ControlIdAndName[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlIdAndName)
            .get<ControlIdAndName[]>(url + '/list')
        )
      );
  }

  public createPersonCustomControl(
    personCustomControlCreation: PersonCustomControlCreation
  ): Observable<PersonCustomControl> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonCustomControl)
            .post<PersonCustomControl>(url, personCustomControlCreation)
        )
      );
  }
}
