export enum ControlType {
  BlacklistControl = 0,
  PepControl = 1,
  OriasControl = 12,
  ResidenceControl = 2,
  GafiControl = 3,
  LegalEntityGafiControl = 4,
  EUControl = 5,
  BaselControl = 6,
  CspControl = 7,
  NafControl = 8,
  NaturalPersonNafControl = 9,
  LegalEntityNafControl = 10,
  MilitaryControl = 11,
  SirenControl = 18,
  NationalityControl = 13,
  FlowEmitterCountryControl = 14,
  FlowReceiverCountryControl = 15,
  ExternalAssetsInFranceControl = 16,
  ExternalAssetsCountryControl = 17,
  AccountFilingBodaccEventControl = 19,
  ModificationBodaccEventControl = 20,
  CreationBodaccEventControl = 21,
  WriteOffBodaccEventControl = 22,
  CollectiveBodaccEventControl = 23,
  SalesBodaccEventControl = 24,
  RegistrationBodaccEventControl = 25,
  CoBrokerControl = 26,
  HeadcountRangeControl = 27,
  AdverseMediaControl = 28,
}

export const NaturalPersonControlType = [
  ControlType.BlacklistControl,
  ControlType.PepControl,
  ControlType.ResidenceControl,
  ControlType.GafiControl,
  ControlType.EUControl,
  ControlType.BaselControl,
  ControlType.CspControl,
  ControlType.NafControl,
  ControlType.NaturalPersonNafControl,
  ControlType.MilitaryControl,
  ControlType.NationalityControl,
  ControlType.FlowEmitterCountryControl,
  ControlType.FlowReceiverCountryControl,
  ControlType.ExternalAssetsInFranceControl,
  ControlType.ExternalAssetsCountryControl,
  ControlType.AdverseMediaControl
];
export const LegalEntityControlType = [
  ControlType.BlacklistControl,
  ControlType.OriasControl,
  ControlType.LegalEntityGafiControl,
  ControlType.LegalEntityNafControl,
  ControlType.SirenControl,
  ControlType.AccountFilingBodaccEventControl,
  ControlType.ModificationBodaccEventControl,
  ControlType.CreationBodaccEventControl,
  ControlType.WriteOffBodaccEventControl,
  ControlType.CollectiveBodaccEventControl,
  ControlType.SalesBodaccEventControl,
  ControlType.RegistrationBodaccEventControl,
  ControlType.CoBrokerControl,
  ControlType.HeadcountRangeControl,
  ControlType.AdverseMediaControl
];

export const BlacklistPepCoreControlType = [
  ControlType.BlacklistControl,
  ControlType.PepControl,
  ControlType.AdverseMediaControl
];
