import {ClassedEntity} from '../../../../domain/ClassedEntity';

export class DocumentDescription extends ClassedEntity {
  public static readonly MODULE: string = 'compliance-binder';
  public static readonly COLLECTION: string = 'documentDescription';

  public defaultValidityDuration?: string;
  public nature: string;
  public max: number;
  public uploadTitle: string;
  public uploadAdviceHtml: string;
  public statusAdviceHtml: string;
  public obseletable?: boolean;

  constructor() {
    super('com.beclm.compliance.binder.api.document.DocumentDescription');
  }
}
