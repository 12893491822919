import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {SubscriptionProposal} from '../../domain/subscriptionProposal/SubscriptionProposal';
import {SubscriptionProposalSearchParam} from '../../domain/subscriptionProposal/SubscriptionProposalSearchParam';
import {PaginatedSubscriptionProposals} from '../../domain/subscriptionProposal/PaginatedSubscriptionProposal';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {SearchParams} from '../../../../domain/SearchParams';
import {StringResponse} from '../../../../domain/StringResponse';

@Injectable()
export class SubscriptionProposalService {
  private readonly baseUrl: string = '/api/subscription-proposals';

  private _subscriptionProposalHistoryToReload = new Subject<boolean>();

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getById(id: string): Observable<SubscriptionProposal> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SubscriptionProposal)
            .get<SubscriptionProposal>(`${url}/${id}`)
        )
      );
  }

  public save(
    subscriptionProposal: SubscriptionProposal
  ): Observable<SubscriptionProposal> {
    if (subscriptionProposal.id !== undefined) {
      return this.update(subscriptionProposal);
    } else {
      return this.create(subscriptionProposal);
    }
  }

  public create(
    subscriptionProposal: SubscriptionProposal
  ): Observable<SubscriptionProposal> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SubscriptionProposal)
            .post<SubscriptionProposal>(`${url}`, subscriptionProposal)
        )
      );
  }

  public upload(
    subscriptionProposal: SubscriptionProposal
  ): Observable<SubscriptionProposal> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SubscriptionProposal)
            .post<SubscriptionProposal>(`${url}`, subscriptionProposal)
        )
      );
  }

  public update(
    subscriptionProposal: SubscriptionProposal
  ): Observable<SubscriptionProposal> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SubscriptionProposal)
            .put<SubscriptionProposal>(`${url}`, subscriptionProposal)
        )
      );
  }

  public findByCurrentCompany(): Observable<SubscriptionProposal[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SubscriptionProposal)
            .get<SubscriptionProposal[]>(`${url}/current-company`)
        )
      );
  }

  public findInProgressByCurrentCompany(): Observable<SubscriptionProposal[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SubscriptionProposal)
            .get<SubscriptionProposal[]>(`${url}/current-company/in-progress`)
        )
      );
  }

  public findPaginatedForCompany(
    searchParam: SubscriptionProposalSearchParam
  ): Observable<PaginatedSubscriptionProposals> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedSubscriptionProposals)
          .get<PaginatedSubscriptionProposals>(`${url}/paginated/company`, {
            params: SearchParams.toHttpParams(searchParam)
          })
      )
    );
  }

  public findPaginated(searchParam: SubscriptionProposalSearchParam): Observable<PaginatedSubscriptionProposals> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedSubscriptionProposals)
          .get<PaginatedSubscriptionProposals>(`${url}/paginated`, {
            params: SearchParams.toHttpParams(searchParam)
          })
      )
    );
  }

  public findByCompanyId(
    companyId: string
  ): Observable<SubscriptionProposal[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SubscriptionProposal)
            .get<SubscriptionProposal[]>(`${url}/company/${companyId}`)
        )
      );
  }

  public delete(id: string): Observable<SubscriptionProposal> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SubscriptionProposal)
            .delete<SubscriptionProposal>(`${url}/${id}`)
        )
      );
  }

  public payAndSubscribe(
    subscriptionProposalId: string,
    setupReusablePaymentMethod: boolean,
    useReusablePaymentMethod: boolean
  ): Observable<StringResponse> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(StringResponse)
          .put<StringResponse>(`${url}/payment/${subscriptionProposalId}`, {
            setupReusablePaymentMethod: setupReusablePaymentMethod,
            useReusablePaymentMethod: useReusablePaymentMethod
          })
      )
    );
  }

  public acceptPayment(
    subscriptionProposalId: string
  ): Observable<SubscriptionProposal> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SubscriptionProposal)
            .put<SubscriptionProposal>(
              `${url}/payment/${subscriptionProposalId}/accept`,
              {}
            )
        )
      );
  }

  public markAsPaid(subscriptionProposalId: string): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) =>
        this.http.put<void>(`${url}/payment/${subscriptionProposalId}/mark-as-paid`, {}))
      );
  }

  public sendReminder(subscriptionProposalId: string): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) =>
        this.http.put<void>(`${url}/payment/${subscriptionProposalId}/send-reminder`, {}))
      );
  }

  public openToCompanyAdmin(subscriptionProposalId: string): Observable<SubscriptionProposal> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) =>
        this.http
          .transformResponseTo(SubscriptionProposal)
          .put<SubscriptionProposal>(`${url}/${subscriptionProposalId}/open-to-company-admin`, {})
      ));
  }

  public generateInvoiceFromQuote(subscriptionProposalId: string): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) =>
        this.http
          .put<void>(`${url}/${subscriptionProposalId}/generate-invoice-from-quote`, {})
      ));
  }

  public loadSubscriptionProposalHistoryToReload(
    subscriptionProposalHistoryToReload: boolean
  ) {
    this._subscriptionProposalHistoryToReload.next(
      subscriptionProposalHistoryToReload
    );
  }

  public getSubscriptionProposalHistoryToReload(): Observable<boolean> {
    return this._subscriptionProposalHistoryToReload.asObservable();
  }
}
