import {PersonIntegratedDataCustomField} from './PersonIntegratedDataCustomField';
import {LocalDate} from '../../../../../../domain/LocalDate';
import {Reference} from '../../../../../../domain/Reference';
import {PersonCustomField} from '../PersonCustomField';
import {Transform} from 'class-transformer';
import {LocalDateTransformer} from '../../../../../../domain/transformation/LocalDateTransformer';

export class LocalDatePersonIntegratedDataCustomField extends PersonIntegratedDataCustomField<LocalDate> {

  @Transform(LocalDateTransformer.transformTo())
  value: LocalDate;

  constructor(
    personCustomFieldRef: Reference<PersonCustomField>,
    personCustomFieldDescriptorId: string
  ) {
    super(personCustomFieldRef, personCustomFieldDescriptorId);
  }
}
