import {Type} from 'class-transformer';
import {ExternalApiRequestStatus} from '../api-request-status/ExternalApiRequestStatus';
import {ApiType} from '../../../../../core-shared/domain/apiUser/ApiType';
import {BeData} from './be/BeData';

export class ExternalApiData {
  @Type(() => BeData)
  beApiData: BeData = new BeData();
  @Type(() => ExternalApiRequestStatus)
  apiRequestsStatus: Map<ApiType, ExternalApiRequestStatus> = new Map<ApiType, ExternalApiRequestStatus>();
}
