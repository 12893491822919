import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {PersonCustomField} from './PersonCustomField';
import {Reference} from '../../../../../domain/Reference';
import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';

export class PersonCustomControl extends ClassedEntity {
  @Transform(ReferenceTransformer.transformTo(PersonCustomField))
  personCustomFieldRef: Reference<PersonCustomField>;
  controlTypeId: number;
  personCustomFieldDescriptorId: string;
}
