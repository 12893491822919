import {DocumentConformityStatus} from './document/DocumentConformityStatus';

export class AstreeData {
  references: string[];
  statuses: AstreeStatus[];
}

export class AstreeStatus {
  name: string;
  reference: string;
  status: DocumentConformityStatus;
}
