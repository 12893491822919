import {Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

const CB_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AComboBoxGenericComponent),
  multi: true
};

/**
 * AComboBoxGenericComponent
 *
 * AComboBoxGenericComponent allow you to manage all the combo-box that use the generic type
 * This component use ng-select library to exploit highly customizable select box
 * All .ts subcomponents of a-combo-box extends BaseComboBoxGenericComponent
 * to have basic ControlValueAccessor methods.
 * To manage Entity Reference, please use a-combo-box-ref.
 *
 * Base Usage : <a-combo-box-generic [items]="items"
                                     [ngModel]="selectedItems"
                                     (ngModelChange)="onChange($event)"
                                     [disabled]="disabled"></a-combo-box-generic>
 *
 * Below you will find the documented fields
 *
 */
@Component({
  selector: 'a-combo-box-generic',
  templateUrl: './a-combo-box-generic.component.html',
  styleUrls: ['./a-combo-box-generic.component.scss'],
  providers: [CB_VALUE_ACCESSOR]
})
export class AComboBoxGenericComponent<T> implements ControlValueAccessor, OnInit {
  // To have more information, please check ng-select documentation
  // All elements showed in ng-select
  @Input() items: T[];
  @Input() disabled = false;
  @Input() appendTo: string;
  @Input() groupByFn: (item: T) => any;
  @Input() groupValueFn: (
    groupId: any,
    children: T[]
  ) => {name: string; total: number};
  @Input() formSubmitted: boolean;
  @Input() required: boolean;
  @Input() fullDropdown: boolean;
  // Enable asyncMode
  @Input() isAsyncSearch = false;
  // Minimal characters user need to start a search (very useful with asyncMode)
  @Input() minTermLength = 3;
  // Enable multiple selectable elements
  @Input() multiple = false;
  // Set a max number of selected elements
  @Input() maxSelectedItems: number;
  // Enable to clear the combobox
  @Input() clearable = true;
  // Could add elements dynamically (like email)
  @Input() addTag: any | boolean = false;
  @Input() searchFn: any;

  @Input() translateKey: string;
  // Need Async : When user start enter some characters parents components start search and give to new items array
  @Output() updateAsyncInput: EventEmitter<T> = new EventEmitter<T>();

  // Label template (selected element)
  @ContentChild('labelTemplate', {static: true})
  labelTemplate: TemplateRef<any>;
  // Option template
  @ContentChild('optionTemplate', {static: true})
  optionTemplate: TemplateRef<any>;
  // Tag template (when add element dynamically) work with addTag
  @ContentChild('tagTemplate', {static: true}) tagTemplate: TemplateRef<any>;


  selection: T | T[];
  searchInput: Subject<T> = new Subject<T>();

  onChange = (_: any) => {};

  onTouched = () => {};

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.isAsyncSearch) {
      this.asyncSearch();
    }
    if (!this.searchFn && this.translateKey) {
      this.searchFn = (term: string, item: any) => {
        term = term.toLocaleLowerCase();
        return (
          this.translateService
            .instant(`${this.translateKey}.${item}`)
            .toLocaleLowerCase()
            .indexOf(term) > -1
        );
      };
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: T | T[]): void {
    this.selection = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public compareFn = (a: T, b: T) => {
    return a === b;
  };

  public asyncSearch() {
    this.searchInput
      .pipe(
        debounceTime(300),
        filter((value) => !!value)
      )
      .subscribe((input: T) => {
        this.updateAsyncInput.emit(input);
      });
  }
}
