import {Component, Input} from '@angular/core';

@Component({
  selector: 'm-help-message',
  templateUrl: './m-help-message.component.html',
  styleUrls: ['./m-help-message.component.scss']
})
export class MHelpMessageComponent {
  showHelp = true;
  @Input()
  public kind: Kind = 'INFO';
  @Input()
  public allowClose: boolean = true;
  fromHelperType = fromHelperType;

  public alignTitleHeader: boolean;

  closeHelpMessage(): void {
    if (this.allowClose) {
      this.showHelp = false;
    }
  }

  openHelpMessage(): void {
    this.showHelp = true;
  }
}

export type Kind = 'NOTICE' | 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR';

export function fromHelperType(type: Kind): string {
  switch (type) {
    case 'NOTICE':
      return '_notice';
    case 'INFO':
      return '_info';
    case 'SUCCESS':
      return '_success';
    case 'WARNING':
      return '_warning';
    case 'ERROR':
      return '_error';
  }
}
