import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../../services/module.service';
import {ServiceDeskMessage} from '../../../domain/adapter/servicedesk/ServiceDeskMessage';
import {ServiceDeskWorkspace} from '../../../domain/adapter/servicedesk/ServiceDeskWorkspace';
import {ServiceDeskOriginApp} from '../../../domain/adapter/servicedesk/ServiceDeskOriginApp';

@Injectable()
export class ServiceDeskService {
  private readonly baseUrl = '/api/servicedesk';

  private _currentServiceDeskMessage = new BehaviorSubject<ServiceDeskMessage>(
    undefined
  );

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getServiceDeskEncrypedToken(): Observable<string> {
    return this.getEncryptedToken(ServiceDeskOriginApp.BECLM_SERVICE_DESK);
  }

  public getRegtechEncrypedToken(): Observable<string> {
    return this.getEncryptedToken(ServiceDeskOriginApp.BECLM_REGTECH);
  }

  public sendServiceDeskMessage(
    serviceDeskMessage: ServiceDeskMessage
  ): Observable<any> {
    return this.sendMessage(ServiceDeskOriginApp.BECLM_SERVICE_DESK, serviceDeskMessage);
  }

  public isEnabled(): Observable<boolean> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .get<boolean>(`${url}/enabled`)
        )
      );
  }

  private getEncryptedToken(originApp: string): Observable<string> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(String)
            .get<string>(
              `${url}/token?base64backUrl=${btoa(
                window.location.href
              )}&originApp=${originApp}`,
              {responseType: 'text'}
            )
        )
      );
  }

  public getBaseUrl(): Observable<string> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(String)
            .get<string>(`${url}/baseurl`, {responseType: 'text'})
        )
      );
  }

  private sendMessage(
    originApp: string,
    serviceDeskMesssage: ServiceDeskMessage
  ): Observable<any> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.post(
            `${url}/message?base64backUrl=${btoa(
              window.location.href
            )}&originApp=${originApp}`,
            serviceDeskMesssage
          )
        )
      );
  }

  public getWorkspaces(): Observable<ServiceDeskWorkspace[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.get<ServiceDeskWorkspace[]>(`${url}/workspaces`)));
  }

  public currentServiceDeskMessage(): Observable<ServiceDeskMessage> {
    return this._currentServiceDeskMessage.pipe(filter((v) => v !== undefined));
  }

  public updateServiceDeskMessage(
    serviceDeskMessage: ServiceDeskMessage
  ): void {
    return this._currentServiceDeskMessage.next(serviceDeskMessage);
  }
}
