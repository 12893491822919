import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {Reference} from '../../../../../domain/Reference';
import {Company} from '../../company/Company';
import {PersonCustomControl} from '../person-custom/PersonCustomControl';
import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {ControlDataSourceGroupType} from './ControlDataSourceGroupType';
import {ScoreType} from './control/ScoreType';
import {SelectableRef} from '../../../../../domain/SelectableRef';

export class ControlDataSource extends ClassedEntity {
  name: string;
  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;
  version: Date;
  isDefault: boolean;
  @Transform(ReferenceTransformer.transformTo(PersonCustomControl))
  personCustomControlRef: Reference<PersonCustomControl>;
  groupType: ControlDataSourceGroupType;
  scoreType: ScoreType;
  description: string;

  public static toSelectableRef(
    reference: Reference<ControlDataSource>
  ): SelectableRef<ControlDataSource> {
    const selectableRef = new SelectableRef<ControlDataSource>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(translatedName: string): SelectableRef<ControlDataSource> {
    const selectableRef = new SelectableRef<ControlDataSource>();
    selectableRef.id = new Reference<ControlDataSource>(this);
    selectableRef.__label = translatedName;
    return selectableRef;
  }
}
