import {Type} from 'class-transformer';
import {Reference} from '../../../../../../domain/Reference';
import {SelectableRef} from '../../../../../../domain/SelectableRef';
import {PoliticalExposure} from './redflag/PoliticalExposure';
import {Person} from '../Person';
import {NaturalPersonIntegratedData} from './NaturalPersonIntegratedData';
import {NaturalPersonExternalApiData} from '../external-api-data/NaturalPersonExternalApiData';

export class NaturalPerson extends Person {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'naturalPerson';

  @Type(() => NaturalPersonIntegratedData)
  personIntegratedData: NaturalPersonIntegratedData = new NaturalPersonIntegratedData();

  @Type(() => PoliticalExposure)
  politicalExposure: PoliticalExposure = new PoliticalExposure();

  @Type(() => NaturalPersonExternalApiData)
  externalApiData: NaturalPersonExternalApiData;

  constructor() {
    super('com.beclm.core.api.person.naturalPerson.NaturalPerson');
  }

  public static toSelectableRef(
    reference: Reference<NaturalPerson>
  ): SelectableRef<NaturalPerson> {
    const selectableRef = new SelectableRef<NaturalPerson>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label =
        reference.entity.personIntegratedData.identity.email +
        ' - ' +
        reference.entity.personIntegratedData.identity.lastName +
        ' ' +
        reference.entity.personIntegratedData.identity.firstName;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<NaturalPerson> {
    const selectableRef = new SelectableRef<NaturalPerson>();
    selectableRef.id = new Reference<NaturalPerson>(this);
    selectableRef.__label =
      this.personIntegratedData.identity.email +
      ' - ' +
      this.personIntegratedData.identity.lastName +
      ' ' +
      this.personIntegratedData.identity.firstName;
    return selectableRef;
  }
}
