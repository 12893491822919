import {ControlConfig} from './ControlConfig';
import {Type} from 'class-transformer';

export class MatchingBatchConfig {
  active = false;
  @Type(() => ControlConfig)
  controlConfig: ControlConfig = new ControlConfig();
  @Type(() => Date)
  lastModificationDate: Date;
}
