import {PersonIntegratedDataCustomField} from './PersonIntegratedDataCustomField';
import {Reference} from '../../../../../../domain/Reference';
import {PersonCustomField} from '../PersonCustomField';

export class BigDecimalPersonIntegratedDataCustomField extends PersonIntegratedDataCustomField<number> {
  constructor(
    personCustomFieldRef: Reference<PersonCustomField>,
    personCustomFieldDescriptorId: string
  ) {
    super(personCustomFieldRef, personCustomFieldDescriptorId);
  }
}
