<ng-container>
  <ng-select
    [loadingText]="'ng-select.loading' | translate"
    [notFoundText]="'ng-select.notFound' | translate"
    [typeToSearchText]="'ng-select.typeSearch' | translate:{minTermLength:minTermLength}"
    [items]="items | async"
    [bindLabel]="'__label'"
    [(ngModel)]="selection"
    [disabled]="disabled"
    [multiple]="multiple"
    [clearable]="clearable"
    [typeahead]="searchInput"
    [loading]="loading"
    [hideSelected]="true"
    (clear)="onChange(null)"
    [minTermLength]="minTermLength"
    [compareWith]="compareFn"
    (ngModelChange)="onChange($event)"
    [required]="required"
  >
  </ng-select>
</ng-container>
