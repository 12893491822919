import {FolderType} from './FolderType';
import {FolderExtraDataDescription} from './FolderExtraDataDescription';
import {ClassedEntity} from '../../../../domain/ClassedEntity';

export class FolderDescription extends ClassedEntity {
  public static readonly MODULE: string = 'compliance-binder';
  public static readonly COLLECTION: string = 'folderDescription';

  public nature: string;
  public type: FolderType;
  public addTitle: string;
  public editTitle: string;
  public infoTitle: string;
  public infoHtml: string;
  public subFoldersExtraDataDescriptions: Record<
    string,
    FolderExtraDataDescription
  > = {};

  constructor() {
    super('com.beclm.compliance.binder.api.folder.FolderDescription');
  }
}
