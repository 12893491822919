import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Folder} from '../domain/folder/Folder';
import {FolderContent} from '../domain/folder/FolderContent';
import {DocumentCompletenessStatusType} from '../domain/document/DocumentCompletenessStatusType';
import {SearchMetadata} from '../domain/SearchMetadata';
import {map, switchMap} from 'rxjs/operators';
import {FolderDescription} from '../domain/folder/FolderDescription';
import {Document} from '../domain/document/Document';
import {DocumentConformityStatusType} from '../domain/document/DocumentConformityStatusType';
import {PaginatedFolders} from '../domain/folder/PaginatedFolders';
import {HttpHandlerService} from '../../../services/http/http-handler.service';
import {ModuleService} from '../../../services/module.service';
import {SearchParams} from '../../../domain/SearchParams';
import {SortOrder} from '../../../domain/Sorter';
import {StringResponse} from '../../../domain/StringResponse';

@Injectable()
export class FolderService {
  private readonly baseUrl = '/api/folders';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public findDeeply(criteria: FolderSearchCriteria): Observable<Folder[]> {
    let params: HttpParams = new HttpParams();

    if (criteria.q) {
      params = params.set('q', criteria.q);
    }
    if (criteria.onlySection) {
      params = params.set('onlySection', criteria.onlySection);
    }
    if (
      criteria.documentCompletenessStatuses &&
      criteria.documentCompletenessStatuses.length > 0
    ) {
      criteria.documentCompletenessStatuses.forEach(
        (documentStatus) =>
          (params = params.append(
            'documentCompletenessStatuses',
            documentStatus
          ))
      );
    }
    if (
      criteria.documentConformityStatuses &&
      criteria.documentConformityStatuses.length > 0
    ) {
      criteria.documentConformityStatuses.forEach(
        (documentStatus) =>
          (params = params.append('documentConformityStatuses', documentStatus))
      );
    }
    if (criteria.ignoreIfMissing || criteria.ignoreIfMissing == false) {
      params = params.set('ignoreIfMissing', criteria.ignoreIfMissing);
    }

    params = params.set('binderId', criteria.binderId.toString());

    return this.moduleService.complianceBinderBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http.transformResponseTo(Folder).get<Folder[]>(url + '/deeply', {
          params: params
        })
      )
    );
  }

  public paginatedFind(
    criteria: FolderSearchCriteria
  ): Observable<PaginatedFolders> {
    return this.moduleService.complianceBinderBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedFolders)
          .get<PaginatedFolders>(url + '/paginated', {
            params: SearchParams.toHttpParams(criteria)
          })
      )
    );
  }

  public content(id: string): Observable<FolderContent> {
    return this.moduleService
      .complianceBinderBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(FolderContent)
            .get<FolderContent>(`${url}/${id}/content`)
        )
      );
  }

  public fullContent(id: string): Observable<FolderContent> {
    return this.moduleService
      .complianceBinderBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(FolderContent)
            .get<FolderContent>(`${url}/${id}/content/full`)
        )
      );
  }

  public countSubFolders(id: string): Observable<number> {
    return this.moduleService
      .complianceBinderBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.get<number>(`${url}/${id}/subFolders/count`)
        )
      );
  }

  public getFolderMetadata(id: string): Observable<Folder> {
    return this.moduleService
      .complianceBinderBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Folder)
            .get<Folder>(`${url}/${id}/metadata`)
        )
      );
  }

  public explore(folder: Folder, folderCriteria: FolderSearchCriteria, searchFullContent: boolean): void {
    if (searchFullContent) {
      this.fullContent(folder.id).subscribe((content) => {
        folder.content = content;
      })
    } else {
      this.content(folder.id).subscribe((content) => {
        folder.content = content;
        if (folderCriteria) {
          this.applyCriteriaOnFolder(folder, folderCriteria);
        }
      });
    }
  }

  public save(folder: Folder): Observable<Folder> {
    if (folder.id) {
      return this.moduleService
        .complianceBinderBackendUrl(this.baseUrl)
        .pipe(
          switchMap((url) =>
            this.http
              .transformResponseTo(Folder)
              .put<Folder>(`${url}/${folder.id}`, folder)
          )
        );
    } else {
      return this.moduleService
        .complianceBinderBackendUrl(this.baseUrl)
        .pipe(
          switchMap((url) =>
            this.http.transformResponseTo(Folder).post<Folder>(`${url}`, folder)
          )
        );
    }
  }

  public delete(folder: Folder): Observable<any> {
    return this.moduleService
      .complianceBinderBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.delete(`${url}/${folder.id}`)));
  }

  public findById(folderId: string): Observable<Folder> {
    return this.moduleService
      .complianceBinderBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Folder)
            .get<Folder>(`${url}/${folderId}`)
        )
      );
  }

  public findFirstFolderIdRelatedToEntity(
    externalRefId: string,
    personDataSourceId: string
  ): Observable<string> {
    return this.moduleService
      .complianceBinderBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(StringResponse)
            .get<StringResponse>(`${url}/related-entity/${externalRefId}/${personDataSourceId}`)
        ),
        map(stringResponse => stringResponse.value)
      )
  }

  public applyCriteriaOnFolders(
    folders: Folder[],
    criteria: FolderSearchCriteria
  ): void {
    folders.forEach((folder) => {
      this.applyCriteriaOnFolder(folder, criteria);
    });
  }

  public applyCriteriaOnFolder(
    folder: Folder,
    criteria: FolderSearchCriteria
  ): boolean {
    if (folder.searchMetadata == null) {
      folder.searchMetadata = new SearchMetadata();
    }
    folder.searchMetadata.matched =
      criteria.q && folder.name.match(RegExp(criteria.q, 'i')) != null;
    let allDocumentHidden = true;
    let allSubFoldersHidden = true;
    if (folder.content) {
      allDocumentHidden = folder.content.documents
        .map((document) => this.hideDocumentIfNoMatch(criteria, document))
        .reduce((v, b) => v && b, true);

      allSubFoldersHidden = folder.content.folders
        .map((subfolder) => this.hideFolderIfNoMatch(subfolder, criteria))
        .reduce((v, b) => v && b, true);
    }
    folder.searchMetadata.hidden =
      criteria.q &&
      folder.section === false &&
      allDocumentHidden &&
      allSubFoldersHidden &&
      !folder.searchMetadata.matched;
    folder.opened = !allDocumentHidden || !allSubFoldersHidden;
    return folder.searchMetadata.hidden;
  }

  private hideFolderIfNoMatch(subfolder, criteria: FolderSearchCriteria) {
    if (subfolder.searchMetadata == null) {
      subfolder.searchMetadata = new SearchMetadata();
    }
    subfolder.searchMetadata.hidden = this.applyCriteriaOnFolder(
      subfolder,
      criteria
    );
    return subfolder.searchMetadata.hidden;
  }

  private hideDocumentIfNoMatch(
    criteria: FolderSearchCriteria,
    document: Document
  ): boolean {
    if (document.searchMetadata == null) {
      document.searchMetadata = new SearchMetadata();
    }
    const filteredByStatus =
      criteria.documentCompletenessStatuses.length === 0 ||
      criteria.documentCompletenessStatuses.find(
        (documentStatus) => documentStatus === document.completenessStatus.type
      ) !== undefined;
    const filteredByName =
      !criteria.q || document.name.match(RegExp(criteria.q, 'i')) != null;
    document.searchMetadata.hidden = !filteredByStatus || !filteredByName;
    document.searchMetadata.matched = criteria.q && filteredByName;
    return document.searchMetadata.hidden;
  }

  public findAllDescriptions(): Observable<FolderDescription[]> {
    return this.moduleService
      .complianceBinderBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(FolderDescription)
            .get<FolderDescription[]>(`${url}/descriptions`)
        )
      );
  }

  public findDescription(id: string): Observable<FolderDescription> {
    return this.moduleService
      .complianceBinderBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(FolderDescription)
            .get<FolderDescription>(`${url}/descriptions/${id}`)
        )
      );
  }
}

export class FolderSearchCriteria {
  q?: string;
  binderId: string;
  documentCompletenessStatuses: DocumentCompletenessStatusType[] = [];
  documentConformityStatuses: DocumentConformityStatusType[] = [];
  onlySection = false;
  parentFolderId: string;
  currentPage: number;
  nbOfResultsPerPage: number;
  ignoreIfMissing: boolean;
  sort: string;
  order: SortOrder;

  static allSectionOf(binderId): FolderSearchCriteria {
    const folderCriteria = new FolderSearchCriteria();
    folderCriteria.onlySection = true;
    folderCriteria.binderId = binderId;
    return folderCriteria;
  }

  static allOfParentFolder(folderId): FolderSearchCriteria {
    const folderCriteria = new FolderSearchCriteria();
    folderCriteria.parentFolderId = folderId;
    return folderCriteria;
  }

  compare(folderCriteria: FolderSearchCriteria): boolean {
    return (
      this.q === folderCriteria.q &&
      this.binderId === folderCriteria.binderId &&
      JSON.stringify(this.documentCompletenessStatuses) ===
        JSON.stringify(folderCriteria.documentCompletenessStatuses) &&
      JSON.stringify(this.documentConformityStatuses) ===
        JSON.stringify(folderCriteria.documentConformityStatuses)
    );
  }
}
