import {Reference} from '../../../../../../domain/Reference';
import {AuditData} from '../../../../../../domain/AuditData';
import {PersonDataSource} from '../../data-source/PersonDataSource';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {State} from '../../../../../../domain/State';

export class LegalEntitySearchElement {
  id: string;
  auditData: AuditData;
  editable: boolean;
  externalRefId: string;
  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  personDataSourceRef: Reference<PersonDataSource>;
  integratedDataName: string;
  sirenApiName: string;
  siren: string;
  @Type(() => Map)
  counterByStatus: Map<State, number>;
}
