import {InsurerContract} from './natural/main-information/InsurerContract';
import {Type} from 'class-transformer';
import {PersonAdditionalInformation} from '../../../../blacklist-pep/domain/person/PersonAdditionalInformation';
import {AccidentEvent} from '../../../../blacklist-pep/domain/person/AccidentEvent';
import {PersonMetaData} from '../../../../blacklist-pep/domain/person/PersonMetaData';
import {
  LocalDatePersonIntegratedDataCustomField
} from '../person-custom/person-integrated-data-custom-field/LocalDatePersonIntegratedDataCustomField';
import {
  StringPersonIntegratedDataCustomField
} from '../person-custom/person-integrated-data-custom-field/StringPersonIntegratedDataCustomField';
import {
  PersonIntegratedDataCustomField
} from '../person-custom/person-integrated-data-custom-field/PersonIntegratedDataCustomField';
import {
  BigDecimalPersonIntegratedDataCustomField
} from '../person-custom/person-integrated-data-custom-field/BigDecimalPersonIntegratedDataCustomField';

export const PERSON_INTEGRATED_DATA_CUSTOM_FIELD_TYPE = {
  discriminator: {
    property: '@class',
    subTypes: [
      {
        value: StringPersonIntegratedDataCustomField,
        name: 'com.beclm.core.api.person.personIntegratedDataCustomField.StringPersonIntegratedDataCustomField'
      },
      {
        value: BigDecimalPersonIntegratedDataCustomField,
        name: 'com.beclm.core.api.person.personIntegratedDataCustomField.BigDecimalPersonIntegratedDataCustomField'
      },
      {
        value: LocalDatePersonIntegratedDataCustomField,
        name: 'com.beclm.core.api.person.personIntegratedDataCustomField.LocalDatePersonIntegratedDataCustomField'
      }
    ]
  }
};

export class PersonIntegratedData {
  @Type(() => InsurerContract)
  contracts: InsurerContract[] = [];
  @Type(() => PersonAdditionalInformation)
  personAdditionalInformation: PersonAdditionalInformation = new PersonAdditionalInformation();
  @Type(() => PersonMetaData)
  personMetaData: PersonMetaData = new PersonMetaData();
  @Type(() => AccidentEvent)
  accidentEvents: AccidentEvent[] = [];
  @Type(() => PersonIntegratedDataCustomField, PERSON_INTEGRATED_DATA_CUSTOM_FIELD_TYPE)
  personCustomFields: PersonIntegratedDataCustomField<any>[] = [];
}
