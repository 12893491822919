import {Reference} from '../../../../domain/Reference';
import {SelectableRef} from '../../../../domain/SelectableRef';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Column} from '../../../core-shared/domain/fileMapping/Column';
import {DefaultFileMappingFieldType} from './DefaultFileMappingFieldType';

export class BlacklistPepFileMappingField
  extends ClassedEntity
  implements Column
{
  public name: string;
  public type: DefaultFileMappingFieldType | string;
  public required: boolean;
  public format: string;
  public technicalName: string;
  readonly columnType: 'export' | 'blacklist-pep' = 'blacklist-pep';

  public static readonly MODULE: string = 'blacklist-pep';
  public static readonly COLLECTION: string = 'fileMapping';

  constructor() {
    super(
      'com.beclm.blacklist.pep.api.fileMapping.BlacklistPepFileMappingField'
    );
  }

  public static toSelectableRef(
    reference: Reference<BlacklistPepFileMappingField>
  ): SelectableRef<BlacklistPepFileMappingField> {
    const selectableRef = new SelectableRef<BlacklistPepFileMappingField>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<BlacklistPepFileMappingField> {
    const selectableRef = new SelectableRef<BlacklistPepFileMappingField>();
    selectableRef.id = new Reference<BlacklistPepFileMappingField>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }
}
