import {Type} from 'class-transformer';
import {Identity} from './main-information/Identity';
import {PostalAddress} from './main-information/PostalAddress';
import {ProfessionalInformation} from './main-information/ProfessionalInformation';
import {FinancialSituation} from './financial/FinancialSituation';
import {ProofOfId} from './main-information/ProofOfId';
import {PERSON_INTEGRATED_DATA_CUSTOM_FIELD_TYPE, PersonIntegratedData} from '../PersonIntegratedData';
import {
  PersonIntegratedDataCustomField
} from '../../person-custom/person-integrated-data-custom-field/PersonIntegratedDataCustomField';

export class NaturalPersonIntegratedData extends PersonIntegratedData {
  @Type(() => Identity)
  identity: Identity = new Identity();
  @Type(() => PostalAddress)
  postalAddress: PostalAddress = new PostalAddress();
  @Type(() => ProfessionalInformation)
  professionalInformation: ProfessionalInformation = new ProfessionalInformation();
  @Type(() => FinancialSituation)
  financialSituation: FinancialSituation = new FinancialSituation();
  @Type(() => ProofOfId)
  proofOfId: ProofOfId = new ProofOfId();
  @Type(() => PersonIntegratedDataCustomField, PERSON_INTEGRATED_DATA_CUSTOM_FIELD_TYPE)
  personCustomFields: PersonIntegratedDataCustomField<any>[] = [];
}
