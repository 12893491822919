import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {PersonDataSource} from '../../domain/person/data-source/PersonDataSource';
import {SearchParams} from '../../../../domain/SearchParams';
import {PersonDataSourceSearchParam} from '../../domain/person/data-source/PersonDataSourceSearchParam';
import {PaginatedPersonDataSource} from '../../domain/person/data-source/PaginatedPersonDataSource';
import {PersonType} from '../../domain/person/person/PersonType';
import {ControlType} from '../../domain/person/person/control/ControlType';
import {ControlFamily} from '../../domain/person/person/control/ControlFamily';
import {
  DefaultPersonDataSourceControlConfigs
} from '../../domain/person/person/control/DefaultPersonDataSourceControlConfigs';
import {PersonDataSourceUserHidden} from '../../domain/person/data-source/PersonDataSourceUserHidden';

@Injectable()
export class PersonDataSourceService {
  private readonly baseUrl = '/api/personDataSource';
  private _currentLegalEntityDataSource = new BehaviorSubject<PersonDataSource>(
    undefined
  );
  private _currentNaturalPersonDataSource =
    new BehaviorSubject<PersonDataSource>(undefined);

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getCurrentCompanyPersonDataSource(): Observable<PersonDataSource[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonDataSource)
            .get<PersonDataSource[]>(`${url}/currentCompany`)
        )
      );
  }

  public findById(id: string): Observable<PersonDataSource> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonDataSource)
            .get<PersonDataSource>(`${url}/${id}`)
        )
      );
  }

  public find(
    param: PersonDataSourceSearchParam
  ): Observable<PaginatedPersonDataSource> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedPersonDataSource)
          .get<PaginatedPersonDataSource>(url, {
            params: SearchParams.toHttpParams(param)
          })
      )
    );
  }

  public save(
    personDataSource: PersonDataSource
  ): Observable<PersonDataSource> {
    if (personDataSource.id) {
      return this.update(personDataSource);
    } else {
      return this.create(personDataSource);
    }
  }

  private create(
    personDataSource: PersonDataSource
  ): Observable<PersonDataSource> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonDataSource)
            .post<PersonDataSource>(url, personDataSource)
        )
      );
  }

  private update(
    personDataSource: PersonDataSource
  ): Observable<PersonDataSource> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonDataSource)
            .put<PersonDataSource>(url, personDataSource)
        )
      );
  }

  public loadCurrentNaturalPersonDataSource(
    personDataSource: PersonDataSource
  ) {
    this._currentNaturalPersonDataSource.next(personDataSource);
  }

  public unloadCurrentNaturalPersonDataSource() {
    this._currentNaturalPersonDataSource.next(undefined);
  }

  public observeCurrentNaturalPersonDataSource(): Observable<PersonDataSource> {
    return this._currentNaturalPersonDataSource.pipe();
  }

  public getCurrentNaturalPersonDataSource(): PersonDataSource {
    return this._currentNaturalPersonDataSource.getValue();
  }

  public loadCurrentLegalEntityDataSource(personDataSource: PersonDataSource) {
    this._currentLegalEntityDataSource.next(personDataSource);
  }

  public unloadCurrentLegalEntityDataSource() {
    this._currentLegalEntityDataSource.next(undefined);
  }

  public observeCurrentLegalEntityDataSource(): Observable<PersonDataSource> {
    return this._currentLegalEntityDataSource.pipe();
  }

  public getCurrentLegalEntityDataSource(): PersonDataSource {
    return this._currentLegalEntityDataSource.getValue();
  }

  public getPersonDataSourcesForCurrentCompany(): Observable<
    PersonDataSource[]
  > {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonDataSource)
            .get<PersonDataSource[]>(url + '/currentCompany')
        )
      );
  }

  public getCurrentCompanyConfiguredControls(personType: PersonType): Observable<ControlType[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(String)
            .get<ControlType[]>(url + '/configured-controls/' + personType)
        )
      );
  }

  public getCurrentCompanyConfiguredControlFamilies(personType: PersonType): Observable<ControlFamily[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlFamily)
            .get<ControlFamily[]>(url + '/configured-control-families/' + personType)
        )
      );
  }

  public getPersonDataSourceDefaultControlConfig(): Observable<DefaultPersonDataSourceControlConfigs> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(DefaultPersonDataSourceControlConfigs)
            .get<DefaultPersonDataSourceControlConfigs>(`${url}/control-configs/default`)
        )
      );
  }

  public getPersonDataSourcesForUserHidden(userId: string): Observable<PersonDataSource[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonDataSource)
            .get<PersonDataSource[]>(`${url}/users/${userId}/hidden`)
        )
    )
  }

  public updateUserHiddenOnPersonDataSources(
    userId: string,
    personDataSourceUserHidden: PersonDataSourceUserHidden
  ): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.put<void>(`${url}/users/${userId}/hidden`, personDataSourceUserHidden)
        )
      )
  }

  public hidePersonDataSourceToAllUsers(
    personDataSourceId: string,
  ): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.put<void>(`${url}/${personDataSourceId}/hide`)
        )
      )
  }
}
