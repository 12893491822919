import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {Person} from '../../domain/person/person/Person';
import {SearchParams} from '../../../../domain/SearchParams';
import {PersonSearchParam} from '../../domain/person/person/PersonSearchParam';
import {PaginatedPersons} from '../../domain/person/person/PaginatedPersons';
import {PersonClassMappers} from '../../domain/person/person/PersonClassMappers';

@Injectable()
export class PersonService {
  private readonly baseUrl = '/api/person';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getById(id: string): Observable<Person> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap(url => this.http
        .transformResponseTo(Person, PersonClassMappers.personClassMapper)
        .get<Person>(url + '/' + id)));
  }

  public externalRefIdIsRegistered(
    externalRefId: string,
    personDataSourceRefUri: string
  ): Observable<boolean> {
    const fixedExternalRefId = externalRefId?.trim() === '' ? undefined : externalRefId;
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http.get<boolean>(`${url}/${fixedExternalRefId}/registered`, {
          params: SearchParams.toHttpParams({
            personDataSourceRefUri: personDataSourceRefUri
          })
        })
      )
    );
  }

  public find(param: PersonSearchParam): Observable<PaginatedPersons> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedPersons)
          .get<PaginatedPersons>(url + '/paginatedFind', {
            params: SearchParams.toHttpParams(param)
          })
      )
    );
  }

  public getPersonType(): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.get<string[]>(url + '/personType')));
  }
}
