import {DocumentDescription} from './DocumentDescription';
import {Type} from 'class-transformer';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Company} from '../../../core/domain/company/Company';
import {SearchMetadata} from '../SearchMetadata';
import {Folder} from '../folder/Folder';
import {DocumentCompletenessStatus} from './DocumentCompletenessStatus';
import {DocumentConformityStatus} from './DocumentConformityStatus';
import {AstreeData} from '../AstreeData';
import {DocumentType} from './DocumentType';
import {DocumentMappingType} from './DocumentMappingType';
import {ExternalApiRequestStatus} from '../../../core/domain/person/person/api-request-status/ExternalApiRequestStatus';
import {ApiType} from '../../../core-shared/domain/apiUser/ApiType';

export class Document extends ClassedEntity {
  public static readonly MODULE: string = 'compliance-binder';
  public static readonly COLLECTION: string = 'document';

  @Type(() => Company)
  company: Company;
  @Type(() => DocumentDescription)
  description: DocumentDescription;
  name: string;
  @Type(() => AstreeData)
  astreeData?: AstreeData;
  parentFolderId: string;
  @Type(() => DocumentCompletenessStatus)
  public completenessStatus: DocumentCompletenessStatus;
  @Type(() => DocumentCompletenessStatus)
  public conformityStatus: DocumentConformityStatus;
  public statusComment = '';
  @Type(() => Date)
  public uploadDate: Date;
  @Type(() => Date)
  public upToDateDate: Date;
  @Type(() => Date)
  public expirationDate: Date;
  public blobIds: string[] = [];
  @Type(() => SearchMetadata)
  public searchMetadata: SearchMetadata = new SearchMetadata();
  public parentFolder: Folder;
  public order: number;
  public documentType: DocumentType;
  public documentMappingType: DocumentMappingType;
  @Type(() => ExternalApiRequestStatus)
  apiRequestsStatus: Map<ApiType, ExternalApiRequestStatus> = new Map<
    ApiType,
    ExternalApiRequestStatus
  >();
  public isInactive: boolean;
  public ignoreIfMissing: boolean;

  constructor() {
    super('com.beclm.compliance.binder.api.document.Document');
  }
}
