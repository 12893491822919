import {Injectable} from '@angular/core';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {SearchParams} from '../../../../domain/SearchParams';
import {ScoredCodedValueSearchParam} from '../../domain/person/person/ScoredCodedValueSearchParam';
import {PaginatedScoredCodedValue} from '../../domain/person/person/PaginatedScoredCodedValue';
import {ScoredCodedValue} from '../../domain/person/person/ScoredCodedValue';

@Injectable()
export class ScoredCodedValueService {
  private readonly baseUrl = '/api/scored-coded-value';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getCurrentCompanyScoredCodedValuesBySourceName(
    controlDataSourceId: string,
  ): Observable<ScoredCodedValue[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ScoredCodedValue)
            .get<ScoredCodedValue[]>(
            url + '/control-data-source/' +
            controlDataSourceId + '/all'
          )
        )
      )
  }

  public getDefaultScoreForValue(
    controlDataSourceId: string,
    value: string
  ): Observable<number> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.get<number>(
            url +
              '/control-data-source/' +
              controlDataSourceId +
              '/default/' +
              value
          )
        )
      );
  }

  public getDefaultScoredCodedValuesByControlDataSourceId(
    controlDataSourceId: string
  ): Observable<ScoredCodedValue[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ScoredCodedValue)
            .get<ScoredCodedValue[]>(
              url +
                '/control-data-source/' +
                controlDataSourceId +
                '/default/all/'
            )
        )
      );
  }

  public paginatedGetCurrentCompanyValuesBySourceName(
    controlDataSourceId: string,
    scoredCodedValueSearchParam: ScoredCodedValueSearchParam
  ): Observable<PaginatedScoredCodedValue> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedScoredCodedValue)
          .get<PaginatedScoredCodedValue>(
            url +
              '/control-data-source/' +
              controlDataSourceId +
              '/paginated/current',
            {
            params: SearchParams.toHttpParams(scoredCodedValueSearchParam)
          })
      )
    );
  }

  public updateScoredValues(
    controlDataSourceId: string,
    scoredCodedValues: ScoredCodedValue[],
    deletion: boolean = false
  ): Observable<ScoredCodedValue[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ScoredCodedValue)
            .put<ScoredCodedValue[]>(
              `${url}/control-data-source/` + controlDataSourceId + `?deletion=` + deletion,
              scoredCodedValues
            )
        )
      );
  }
}
