import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PersonCustomField} from '../../domain/person/person-custom/PersonCustomField';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {PersonCustomFieldDescriptor} from '../../domain/person/person-custom/PersonCustomFieldDescriptor';
import {PersonCustomFieldDescriptorName} from '../../domain/person/person-custom/PersonCustomFieldDescriptorName';

@Injectable()
export class PersonCustomFieldsService {
  private readonly baseUrl = '/api/person-custom-fields';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getForCurrentCompany(): Observable<PersonCustomField> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonCustomField)
            .get<PersonCustomField>(url + '/company/current')
        )
      );
  }

  public getForPersonDataSource(personDataSourceId: string): Observable<PersonCustomField> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonCustomField)
            .get<PersonCustomField>(url + '/person-data-source/' + personDataSourceId)
        )
      );
  }

  public addNewField(
    personCustomFieldId: string,
    personCustomFieldDescriptor: PersonCustomFieldDescriptor
  ): Observable<PersonCustomField> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.post<PersonCustomField>(
            url + `/${personCustomFieldId}/person-custom-field-descriptor`,
            personCustomFieldDescriptor
          )
        )
      );
  }

  public updateField(
    personCustomFieldId: string,
    personCustomFieldDescriptor: PersonCustomFieldDescriptor
  ): Observable<PersonCustomField> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.put<PersonCustomField>(
            url + `/${personCustomFieldId}/person-custom-field-descriptor`,
            personCustomFieldDescriptor
          )
        )
      );
  }

  public deleteField(
    personCustomFieldId: string,
    personCustomFieldDescriptorId: string
  ): Observable<PersonCustomField> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.delete<PersonCustomField>(
            url +
              `/${personCustomFieldId}/person-custom-field-descriptor/${personCustomFieldDescriptorId}`
          )
        )
      );
  }

  public getPersonCustomFieldDescriptorsNames(): Observable<
    PersonCustomFieldDescriptorName[]
  > {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonCustomFieldDescriptorName)
            .get<PersonCustomFieldDescriptorName[]>(
              url + '/company/current/descriptors'
            )
        )
      );
  }
}
