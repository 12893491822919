import {FolderDescription} from './FolderDescription';
import {FolderContent} from './FolderContent';
import {Transform, Type} from 'class-transformer';
import {SearchMetadata} from '../SearchMetadata';
import {FolderType} from './FolderType';
import {FolderPathItem} from './FolderPathItem';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Company} from '../../../core/domain/company/Company';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';
import {PersonDataSource} from '../../../core/domain/person/data-source/PersonDataSource';
import {Reference} from '../../../../domain/Reference';

export class Folder extends ClassedEntity {
  public static readonly MODULE: string = 'compliance-binder';
  public static readonly COLLECTION: string = 'folder';

  @Type(() => Company)
  company: Company;
  @Type(() => FolderDescription)
  public description: FolderDescription;
  public aggregatedDocumentCompletenessStatus: {[index: string]: number};
  public aggregatedDocumentConformityStatus: {[index: string]: number};
  parentFolderId: string;
  @Type(() => Folder)
  public parentFolder: Folder;
  public path: FolderPathItem[];
  @Type(() => FolderContent)
  public content: FolderContent;
  public name: string;
  public extraDataValues: Record<string, unknown> = {};
  public order: number;

  // only when is metadata
  public icon: string;
  // TODO FHE : section doesn't work but work with isSection from Angular 10 why it worked before ??? because object send isSection from backend
  public section: boolean;
  public isSection: boolean;
  public type: FolderType;

  // added from front
  public opened = false;
  // used only on front
  public menuOpened = false;
  @Type(() => SearchMetadata)
  public searchMetadata: SearchMetadata = new SearchMetadata();

  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  public personDataSourceRef: Reference<PersonDataSource>[] = [];

  public lockedMappings: string[];

  public relatedEntityId: string;

  constructor() {
    super('com.beclm.compliance.binder.api.folder.Folder');
  }
}
