import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {Reference} from '../../../../../domain/Reference';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {Company} from '../../company/Company';
import {PersonCustomFieldDescriptor} from './PersonCustomFieldDescriptor';

export class PersonCustomField extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'personCustomField';

  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;
  @Type(() => PersonCustomFieldDescriptor)
  personCustomFields: PersonCustomFieldDescriptor[] = [];
}
