import {PersonCustomFieldType} from './PersonCustomFieldType';
import {PersonCustomFieldValue} from './PersonCustomFieldValue';
import {Type} from 'class-transformer';

export class PersonCustomFieldDescriptor {
  id: string;
  name: string;
  type: PersonCustomFieldType;
  @Type(() => PersonCustomFieldValue)
  values: PersonCustomFieldValue[] = [];
}
