import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {PersonCustomField} from '../PersonCustomField';
import {Reference} from '../../../../../../domain/Reference';

export abstract class PersonIntegratedDataCustomField<T> {
  @Transform(ReferenceTransformer.transformTo(PersonCustomField))
  personCustomFieldRef: Reference<PersonCustomField>;
  personCustomFieldDescriptorId: string;
  value: T;

  constructor(
    personCustomFieldRef: Reference<PersonCustomField>,
    personCustomFieldDescriptorId: string
  ) {
    this.personCustomFieldRef = personCustomFieldRef;
    this.personCustomFieldDescriptorId = personCustomFieldDescriptorId;
  }
}
