import {NgModule} from '@angular/core';
import {SafeHtmlPipe} from './safe.html.pipe';
import {JoinPipe} from './join.html.pipe';
import {MapPropertyPipe} from './mapProperty.html.pipe';
import {TranslateValues} from './translateValues.html.pipe';
import {SafePipe} from './safe.pipe';
import {OverflowPipe} from './overflow.html.pipe';
import {DurationPipe} from './duration.pipe';
import {ExtendedDatePipe} from './extended-date.pipe';
import {ShowCharIfNullPipe} from './show-char-if-null.pipe';

const pipes = [
  SafeHtmlPipe,
  JoinPipe,
  MapPropertyPipe,
  TranslateValues,
  SafePipe,
  OverflowPipe,
  DurationPipe,
  ExtendedDatePipe,
  ShowCharIfNullPipe
];

@NgModule({
  exports: pipes,
  declarations: pipes,
  imports: []
})
export class BasePipesModule {}
