import {Reference} from '../../../../../../domain/Reference';
import {ControlFamily} from './ControlFamily';
import {ControlCriticality} from './ControlCriticality';
import {Expose, Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';

export class PersonDataSourceControlConfig {
  @Transform(ReferenceTransformer.transformTo(ControlFamily))
  familyRef: Reference<ControlFamily>;

  @Expose({name: 'criticality', toPlainOnly: true})
  _criticality: ControlCriticality;

  riskThreshold: number;

  get criticality(): ControlCriticality {
    if (this._criticality == ControlCriticality.MINOR) {
      return null;
    }

    return this._criticality;
  }

  set criticality(c) {
    if (c == null) {
      c = ControlCriticality.MINOR;
    }

    this._criticality = c;
  }

  constructor(
    familyRef?: Reference<ControlFamily>,
    criticality?: ControlCriticality,
    riskThreshold?: number
  ) {
    this.familyRef = familyRef;
    this.criticality = criticality;
    this.riskThreshold = riskThreshold;
  }
}
